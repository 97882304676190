import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { t } from "@lingui/macro";
import { captureEvent } from "@sentry/nextjs";
import {
  DisableTwoFactorAuthenticationDocument,
  DisableTwoFactorAuthenticationMutation,
  DisableTwoFactorAuthenticationMutationVariables,
  GetScopedUserForEditDocument,
  GetScopedUserForEditQuery,
  GetScopedUserForEditQueryVariables,
  GetUserForEditDocument,
  GetUserForEditQuery,
  GetUserForEditQueryVariables,
  PlanningRangeTypeEnum,
  SetUserProfilePlanningRangesDocument,
  SetUserProfilePlanningRangesMutation,
  SetUserProfilePlanningRangesMutationVariables,
  SetUserProfileRatesDocument,
  SetUserProfileRatesMutation,
  SetUserProfileRatesMutationVariables,
  UpdateUserDocument,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from "@src/__generated__/graphql";
import { EditUserModal } from "@src/components/modules/users/listing/form/EditUserModal";
import { FormUser } from "@src/components/modules/users/listing/form/FormUser";
import { MutationHelper } from "@src/helpers/apollo/mutation";
import { client } from "@src/services/apollo-client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { UserType } from "@src/stores/models/Me";
import { toApiDate } from "@src/utils/dates";
import { denominate, nominate } from "@src/utils/formatters";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { WeekDays } from "@src/utils/types";
import { GraphQLError } from "graphql";
import { map, omit } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import Router from "next/router";

export type TCategory =
  | "personal"
  | "notifications"
  | "planning-utilization"
  | "access"
  | "hourly-rates";
type TUser =
  | NonNullable<GetUserForEditQuery["user"]>
  | NonNullable<GetScopedUserForEditQuery["user"]>;
type EditUserModalOptions = {
  userId: string;
  userType: UserType;
  onSubmit?: () => void;
};

export class EditUserModalStore implements BaseStore, ModalStore {
  appStore: AppStore;
  readonly modalId = "userEditModal";

  private userMutator = new MutationHelper<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UpdateUserDocument);
  private ratesMutator = new MutationHelper<
    SetUserProfileRatesMutation,
    SetUserProfileRatesMutationVariables
  >(SetUserProfileRatesDocument);
  private planningRangesMutator = new MutationHelper<
    SetUserProfilePlanningRangesMutation,
    SetUserProfilePlanningRangesMutationVariables
  >(SetUserProfilePlanningRangesDocument);

  @observable category: TCategory = "personal";
  @observable.ref form: FormUser;
  @observable.ref user?: TUser;
  @observable isLoading = false;
  @observable modalState = new DisclosureState<EditUserModalOptions>({
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
      this.form.reset();
      this.setCategory("personal");
      this.user = undefined;
    },
    onOpen: (additionalData) => {
      this.form.userType = additionalData?.userType!;
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <EditUserModal />,
      });
      this.fetchUser();
    },
  });

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
    this.form = new FormUser({
      formVariant: "edit",
      appStore,
    });
  }

  @computed get isSubmitting(): boolean {
    return (
      this.userMutator.isLoading.value ||
      this.ratesMutator.isLoading.value ||
      this.planningRangesMutator.isLoading.value
    );
  }

  async fetchUser() {
    this.isLoading = true;

    const query = {
      internal: GetUserForEditDocument as TypedDocumentNode<
        GetUserForEditQuery,
        GetUserForEditQueryVariables
      >,
      client: GetScopedUserForEditDocument as TypedDocumentNode<
        GetScopedUserForEditQuery,
        GetScopedUserForEditQueryVariables
      >,
      partner: GetScopedUserForEditDocument as TypedDocumentNode<
        GetScopedUserForEditQuery,
        GetScopedUserForEditQueryVariables
      >,
    }[this.modalState.additionalData!.userType];

    try {
      const { data } = await client.query({
        query,
        variables: { id: this.modalState.additionalData!.userId },
      });

      if (!data?.user) {
        throw new Error("No data returned from the server");
      }

      this.user = data.user;
      this.fillUserForm(data.user);
    } catch (error) {
      this.modalState.onClose();
      this.appStore.UIStore.toast({
        title: t`Unable to load user. Please try again later.`,
        status: "error",
      });
      captureEvent({
        message: "Unable to load user",
        extra: { error },
      });
    }
    this.isLoading = false;
  }

  fillUserForm(user: TUser) {
    this.form.created_at.onChange(user.created_at);
    this.form.first_name.onChange(user.first_name);
    this.form.last_name.onChange(user.last_name);
    this.form.codename.onChange(user.codename || "");
    this.form.email.onChange(user.email);
    this.form.phone.onChange(user.phone || "");
    this.form.note.onChange(user.note || "");
    this.form.team_id.onChange(user.team?.id || "");
    this.form.role_ids.onChange(map(user.roles, (r) => r.id));
    this.form.avatar.onChange(user.image?.urls.thumbnail);
    this.form.brand_ids.onChange(user.brands.map((b) => b.id));
    this.form.task_ids.onChange(user.tasks.map((t) => t.id));
    this.form.muted_mail_notifications.onChange(user.muted_mail_notifications);
    this.form.task_ids.onChange(user.tasks.map((task) => task.id));
    if ("projects" in user) {
      this.form.project_ids.onChange(
        user.projects.map((project) => project.id),
      );
    }
    if ("scope_access" in user && user.scope_access) {
      this.form.scope_access.onChange(user.scope_access);
    }
    if ("profile" in user && user.profile) {
      this.form.default_work_type_id.onChange(user.profile.defaultWorktype.id);
      user.profile.rates.map((rate) => {
        this.form.addHourlyRate(undefined, "add", {
          id: rate.id,
          valid_from: new Date(rate.valid_from),
          valid_to: rate.valid_to ? new Date(rate.valid_to) : undefined,
          rate: nominate(
            rate.rate,
            this.appStore.workspaceStore.settings?.currency.denomination!,
          ),
        });
      });
      user.profile.planningRanges.map((range) => {
        this.form.addPlanningRange(undefined, "add", {
          id: range.id,
          type: range.type.value,
          valid_from: new Date(range.valid_from),
          valid_to: range.valid_to ? new Date(range.valid_to) : undefined,
          utilising: range.utilising,
          weekly_capacities: !!range.weekly_capacities
            ? (omit(range.weekly_capacities, "__typename") as WeekDays<number>)
            : undefined,
          daily_capacity: range.daily_capacity ?? undefined,
        });
      });
      this.form.plannable.onChange(user.profile.plannable);
      this.form.hex_color.onChange(user.profile.hex_color);
    }
  }

  @action.bound
  setCategory(category: TCategory) {
    this.category = category;
  }

  @action.bound async onSubmit() {
    if (!this.modalState.additionalData) {
      captureEvent({ message: "FE: Missing user modal additional data." });
      this.appStore.UIStore.toast({ status: "error" });
      return;
    }

    const userId = this.modalState.additionalData.userId;
    const isInternalUser =
      this.modalState.additionalData.userType === "internal";
    const hasClientScope = this.modalState.additionalData.userType === "client";

    const [, userError] = await this.userMutator.mutate({
      input: {
        id: userId,

        // Personal settings
        first_name: this.form.first_name.$,
        last_name: this.form.last_name.$,
        codename: this.form.codename.$,
        email: this.form.email.$,
        photo:
          this.form.avatar.$ instanceof File ? this.form.avatar.$ : undefined,
        phone: this.form.phone.$,
        note: this.form.note.$,
        task_ids: !isInternalUser ? this.form.task_ids.$ : undefined,
        team_id: isInternalUser ? this.form.team_id.$ : undefined,
        default_work_type_id: isInternalUser
          ? this.form.default_work_type_id.$
          : undefined,
        role_ids: isInternalUser ? this.form.role_ids.$ : undefined,
        password: isInternalUser ? this.form.password.$ : undefined,

        // Access settings
        scope_access: hasClientScope ? this.form.scope_access.$ : undefined,
        scope_task_ids: hasClientScope ? this.form.task_ids.$ : undefined,
        scope_project_ids: hasClientScope ? this.form.project_ids.$ : undefined,
        brand_ids: hasClientScope ? this.form.brand_ids.$ : undefined,

        // Planning settings
        plannable: this.form.plannable.$,
        hex_color: this.form.hex_color.$,

        // Notification settings
        muted_mail_notifications: this.form.muted_mail_notifications.$,
      },
    });

    let ratesError: readonly GraphQLError[] | Error | null = null;
    let planningRangesError: readonly GraphQLError[] | Error | null = null;

    if (isInternalUser) {
      const [, userRatesError] = await this.ratesMutator.mutate({
        user_id: userId,
        rates: this.form.hourlyRates.$.map((rate) => ({
          valid_from: toApiDate(rate.$.valid_from.value!),
          valid_to: !!rate.$.valid_to.value
            ? toApiDate(rate.$.valid_to.value)
            : null,
          rate: denominate(
            rate.$.rate.value,
            this.appStore.workspaceStore.settings?.currency.denomination!,
          ),
        })),
      });
      ratesError = userRatesError;

      const [, userRangesError] = await this.planningRangesMutator.mutate({
        user_id: userId,
        planningRanges: this.form.planningRanges.$.map((range) => {
          const customRangeType =
            range.$.type.value === PlanningRangeTypeEnum.Custom;

          return {
            valid_from: toApiDate(range.$.valid_from.value!),
            valid_to: !!range.$.valid_to.value
              ? toApiDate(range.$.valid_to.value)
              : null,
            utilising: range.$.utilising.value,
            type: range.$.type.value,
            weekly_capacities: customRangeType
              ? range.$.weekly_capacities.value
              : null,
            daily_capacity: customRangeType
              ? null
              : range.$.daily_capacity.value,
          };
        }),
      });
      planningRangesError = userRangesError;
    }

    if (userError || ratesError || planningRangesError) {
      this.appStore.UIStore.toast({
        status: "error",
      });
      return;
    }

    this.modalState.additionalData?.onSubmit?.();
    this.modalState.onClose();
  }
  async disable2FA() {
    const userId = this.modalState.additionalData!.userId;
    if (!userId) return;

    const { data } = await client.mutate<
      DisableTwoFactorAuthenticationMutation,
      DisableTwoFactorAuthenticationMutationVariables
    >({
      mutation: DisableTwoFactorAuthenticationDocument,
      variables: {
        id: userId,
      },
    });

    if (!data?.disableTwoFactorAuthentication) return;

    this.modalState.additionalData?.onSubmit?.();
  }

  enable2FA() {
    const currentLocation = window.location.href;

    Router.push({
      pathname: "/auth/two-factor/enable",
      query: { redirect_to: currentLocation },
    });
  }

  @action.bound handleBrandSelect(brandIds: string[]) {
    if (this.form.brand_ids.$.length > brandIds.length) {
      this.form.project_ids.reset([]);
      this.form.task_ids.reset([]);
    }
    this.form.brand_ids.onChange(brandIds);
  }

  @action.bound handleProjectSelect(projectIds: string[]) {
    if (this.form.project_ids.$.length > projectIds.length) {
      this.form.task_ids.reset([]);
    }
    this.form.project_ids.onChange(projectIds);
  }

  @action.bound handleTaskSelect(taskIds: string | string[]) {
    this.form.task_ids.onChange(Array.isArray(taskIds) ? taskIds : [taskIds]);
  }
}
