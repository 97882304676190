import {
  FetchPolicy,
  OperationVariables,
  TypedDocumentNode,
} from "@apollo/client";
import { client } from "@src/services/apollo-client";
import { appStore } from "@src/stores/AppStore";
import { GraphQLError } from "graphql";
import { action, makeObservable, observable } from "mobx";
import { BooleanState } from "../../utils/mobx/states/BooleanState";

export class FetchHelper<Query, Variables extends OperationVariables> {
  isLoading = new BooleanState(false);
  @observable.ref private lastResponse: Query | null = null;
  @observable.ref private cachedResponse: Query | null = null;

  constructor(
    private document: TypedDocumentNode<Query, Variables>,
    private cacheResponseAutomatically?: boolean,
  ) {
    makeObservable(this);
  }

  @action.bound cacheResponse(response?: Query) {
    if (response) {
      this.lastResponse = response;
      this.cachedResponse = response;
    } else {
      this.cachedResponse = this.lastResponse;
    }
  }

  async fetch(
    variables?: Variables,
    signal?: AbortSignal | null,
    disableErrorToasts = false,
    silent = false,
    fetchPolicy?: FetchPolicy,
  ): Promise<[Query, null] | [null, readonly GraphQLError[] | Error]> {
    !silent && this.isLoading.on();

    try {
      const { data, errors } = await client.query({
        query: this.document,
        errorPolicy: "all",
        variables: variables,
        fetchPolicy: fetchPolicy,
        context: {
          fetchOptions: {
            signal: signal,
          },
        },
      });

      this.isLoading.off();

      if (errors) {
        !disableErrorToasts &&
          errors.forEach((error) =>
            appStore.UIStore.toast({
              status: "error",
              title: error.message,
            }),
          );
        return [null, errors];
      }

      if (!data) {
        throw new Error("No data returned from fetch call");
      }

      this.lastResponse = data;

      if (this.cacheResponseAutomatically) {
        this.cacheResponse();
      }

      return [data, null];
    } catch (error) {
      console.error(error);

      if (error instanceof Error && !error.message.includes("aborted")) {
        this.isLoading.off();
      }

      return [null, error as Error];
    }
  }

  getCachedResponse(): Query | null {
    return this.cachedResponse;
  }
}
