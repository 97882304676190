import { t } from "@lingui/macro";
import {
  ProjectInvoicedStatusEnum,
  ProjectListFilterOptionsQuery,
  ProjectListTableDataQuery,
  ProjectOrderByColumnEnum,
  ProjectsWhereColumn,
  SortOrder,
  SqlOperator,
} from "@src/__generated__/graphql";
import {
  FinishedProjectListTableDataQuery,
  FinishedProjectListTableSummaryQuery,
  ProjectListTableSummaryQuery,
  ProjectTypeEnum,
} from "@src/__generated__/urql-graphql";
import { IOption } from "@src/components/ui-kit";
import { ProjectCategorySelectBottomExtraContent } from "@src/components/ui-kit/ProjectCategorySelect";
import {
  commonQueryVariables,
  commonSearchParams,
} from "@src/utils/apolloHelpers";
import { Filter, Filters } from "@src/utils/components/filters/models";
import { can } from "@src/utils/components/permissions";
import { OrderBy } from "@src/utils/components/sorting/OrderBy";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { action, computed, makeObservable, observable } from "mobx";
import mapToOptions from "../../../../../utils/map-to-options";
import { Project } from "../models";

export const getBillingCategoryOptions = (): IOption[] => [
  { value: "true", label: t`Billable` },
  { value: "false", label: t`Non-billable` },
];

export enum QuickFilter {
  Highlighted = "highlighted",
  Active = "active",
  MyActive = "my_active",
  Finished = "finished",
  Archived = "archived",
}

type TFinishedProjectQuery = NonNullable<
  FinishedProjectListTableDataQuery["projects"]
>["data"][0];
type TFinishedProjectSimple = Pick<
  TFinishedProjectQuery,
  "id" | "code" | "title" | "is_pinned" | "projectManager"
>;
type TFinishedProjectExtraData = Partial<
  Omit<
    TFinishedProjectQuery,
    "id" | "code" | "title" | "is_pinned" | "projectManager"
  >
>;
export type TFinishedProject = TFinishedProjectSimple &
  TFinishedProjectExtraData;

export class Store {
  // Table data
  @observable.ref projects: Project[] = [];
  @observable.ref finishedProjects: TFinishedProject[] = [];
  @observable.ref
  projectsSummary?: ProjectListTableSummaryQuery["projectsSummary"];
  @observable.ref
  finishedProjectsSummary?: FinishedProjectListTableSummaryQuery["projectsSummary"];
  refetch?: () => void;

  @observable.ref selectedProjectsIds: Project["id"][] = [];

  @observable searchTerm = "";

  pagination = new PaginationState("projects", {
    onChangePagination: () => {
      this.projects = [];
      this.finishedProjects = [];
    },
  });

  orderBy = new OrderBy<ProjectOrderByColumnEnum>([
    { column: ProjectOrderByColumnEnum.ProjectCode, order: SortOrder.Asc },
  ]);

  where = new Filters<ProjectsWhereColumn>(
    [
      new Filter({
        column: ProjectsWhereColumn.BrandId,
        operator: SqlOperator.In,
        title: t`Client/Brand`,
        tagTitle: t`Brand`,
        options: [],
        sectioned: true,
        hasSelectAllOption: true,
      }),
      new Filter({
        column: ProjectsWhereColumn.ActiveInTimeframe,
        operator: SqlOperator.Between,
        title: t`Active in time frame`,
        options: [],
        dateRange: true,
      }),
      new Filter({
        column: ProjectsWhereColumn.StartDate,
        operator: SqlOperator.Between,
        title: t`Project start date`,
        options: [],
        dateRange: true,
      }),
      new Filter({
        column: ProjectsWhereColumn.ExpectedEndDate,
        operator: SqlOperator.Between,
        title: t`Project end date`,
        options: [],
        dateRange: true,
      }),
      new Filter({
        column: ProjectsWhereColumn.ProjectManagerId,
        operator: SqlOperator.In,
        title: t`Project Manager`,
        options: [],
        hasSelectAllOption: true,
      }),
      new Filter({
        column: ProjectsWhereColumn.OtherProjectManagerId,
        operator: SqlOperator.In,
        title: t`Other Project Manager`,
        options: [],
        hasSelectAllOption: true,
      }),
      new Filter({
        column: ProjectsWhereColumn.TeamId,
        operator: SqlOperator.In,
        title: t`Team`,
        options: [],
      }),
      new Filter({
        column: ProjectsWhereColumn.InvoicedStatus,
        operator: SqlOperator.In,
        title: t`Status`,
        options: [
          {
            label: t`uninvoiced`,
            value: ProjectInvoicedStatusEnum.Uninvoiced,
          },
          {
            label: t`partially invoiced`,
            value: ProjectInvoicedStatusEnum.PartiallyInvoiced,
          },
          {
            label: t`invoiced`,
            value: ProjectInvoicedStatusEnum.Invoiced,
          },
        ],
      }),
      new Filter({
        column: ProjectsWhereColumn.Billable,
        operator: SqlOperator.Eq,
        title: t`Billing category`,
        options: getBillingCategoryOptions(),
      }),
      new Filter({
        column: ProjectsWhereColumn.Type,
        operator: SqlOperator.Eq,
        title: t`Type`,
        options: [
          {
            value: ProjectTypeEnum.FixedPrice,
            label: t`Fixed-price`,
          },
          {
            value: ProjectTypeEnum.TimeMaterials,
            label: t`Time & Materials`,
          },
        ],
      }),
      new Filter({
        column: ProjectsWhereColumn.QuickFilter,
        operator: SqlOperator.Eq,
        title: t`Projects`,
        hidden: true,
        options: [],
      }),
      new Filter({
        column: ProjectsWhereColumn.CategoryId,
        operator: SqlOperator.In,
        title: t`Project category`,
        options: [],
        bottomExtraContent: <ProjectCategorySelectBottomExtraContent />,
      }),
      new Filter({
        column: ProjectsWhereColumn.IsTemplate,
        operator: SqlOperator.Eq,
        hidden: true,
        options: [],
        value: "false",
      }),
      new Filter({
        column: ProjectsWhereColumn.ProjectStageId,
        operator: SqlOperator.Eq,
        hidden: true,
        options: [],
      }),
    ],
    {
      onChange: () => {
        this.projects = [];
        this.pagination.resetPage();
      },
    },
  );

  constructor() {
    makeObservable(this);

    if (can("project_read_own") && !can("project_read_all")) {
      this.quickFilter.setValue(QuickFilter.MyActive);
    } else {
      this.quickFilter.setValue(QuickFilter.Active);
    }
  }

  get quickFilter() {
    return this.where.filtersByColumn.get(ProjectsWhereColumn.QuickFilter)!;
  }

  @computed get pipedriveStageFilter() {
    return this.where.filtersByColumn.get(ProjectsWhereColumn.ProjectStageId);
  }

  @computed get searchParams() {
    return commonSearchParams(this);
  }

  @computed get categoryFilter() {
    return this.where.filtersByColumn.get(ProjectsWhereColumn.CategoryId);
  }

  @computed get queryVariables() {
    const vars = commonQueryVariables(this);

    if (can(["project_pin_all", "project_pin_team", "project_pin_own"])) {
      vars.filters.orderBy = [
        { column: ProjectOrderByColumnEnum.Pinned, order: SortOrder.Desc },
        ...vars.filters.orderBy!,
      ];
    }

    return vars;
  }

  @computed get isFinishedOrArchived() {
    const quickFilterValue = this.quickFilter.value[0];
    return (
      quickFilterValue === QuickFilter.Archived ||
      quickFilterValue === QuickFilter.Finished
    );
  }

  @action.bound handleChangeTab(newFilterValue: QuickFilter) {
    this.pipedriveStageFilter?.clear();
    this.quickFilter.setValue(newFilterValue);
  }

  @action
  setTableData(data: ProjectListTableDataQuery) {
    this.projects = (data.projects?.data ?? []).map((i) => new Project(i));
    this.pagination.setFromPaginatorInfo(data.projects?.paginatorInfo!);
  }

  @action
  setTableOptions(data: ProjectListFilterOptionsQuery) {
    const pmOptions = mapToOptions.projectManagers.toOptions(
      data.projectManagerSimpleMap,
    );

    this.where.filtersByColumn
      .get(ProjectsWhereColumn.ProjectManagerId)
      ?.setOptions(pmOptions);
    this.where.filtersByColumn
      .get(ProjectsWhereColumn.OtherProjectManagerId)
      ?.setOptions(pmOptions);

    this.where.filtersByColumn.get(ProjectsWhereColumn.BrandId)?.setOptions(
      data.clientsSimpleMap.map((i) => ({
        value: i?.id,
        label: i?.name,
        options: i?.brands.map((brand) => {
          return {
            label: brand.name,
            value: brand.id,
          };
        })!,
      })),
    );

    this.where.filtersByColumn.get(ProjectsWhereColumn.TeamId)?.setOptions(
      data.teamSimpleMap.map((i) => ({
        value: i?.id,
        label: i?.name,
      })),
    );
  }

  @action.bound
  setSelectedProjectIds(ids: string[]) {
    this.selectedProjectsIds = ids;
  }
}
