import { t } from "@lingui/macro";
import {
  BudgetOrderByColumnEnum,
  BudgetWhereColumn,
  BudgetsFilterOptionsDocument,
  BudgetsFilterOptionsQuery,
  BudgetsFilterOptionsQueryVariables,
  GetBudgetQuotesDocument,
  GetBudgetQuotesQuery,
  GetBudgetQuotesQueryVariables,
  SqlOperator,
} from "@src/__generated__/graphql";
import { getTypeOptions } from "@src/components/modules/budgets/export/Store";
import { BudgetQuoteModel } from "@src/components/modules/budgets/quotes/budget-quote-model";
import { FetchHelper } from "@src/helpers/apollo/fetch";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { Filter, Filters } from "@src/utils/components/filters/models";
import { OrderBy } from "@src/utils/components/sorting/OrderBy";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { action, computed, makeObservable, observable } from "mobx";
import { default as Router } from "next/router";

export class BudgetQuotesListingStore implements BaseStore {
  readonly tableKey = "budget-quotes-listing";

  fetcher = new FetchHelper<
    GetBudgetQuotesQuery,
    GetBudgetQuotesQueryVariables
  >(GetBudgetQuotesDocument);

  @observable pagination = new PaginationState(this.tableKey, {
    onChangePagination: () => {
      this.fetchBudgetQuotes();
    },
  });

  @observable orderBy = new OrderBy<BudgetOrderByColumnEnum>([]);
  @observable tableData: BudgetQuoteModel[] = [];
  @observable where = new Filters<BudgetWhereColumn>(
    [
      new Filter({
        title: t`Brand`,
        column: BudgetWhereColumn.BrandId,
        operator: SqlOperator.In,
        options: [],
      }),
      new Filter({
        title: t`Project`,
        column: BudgetWhereColumn.ProjectId,
        operator: SqlOperator.In,
        options: [],
      }),
      new Filter({
        title: t`Type`,
        hidden: true,
        column: BudgetWhereColumn.Type,
        operator: SqlOperator.In,
        options: getTypeOptions(),
      }),
      new Filter({
        title: t`Internally approved`,
        column: BudgetWhereColumn.InternallyApproved,
        operator: SqlOperator.Eq,
        options: [
          { label: t`Yes`, value: "true" },
          { label: t`No`, value: "false" },
        ],
      }),
      new Filter({
        title: t`Client approved`,
        column: BudgetWhereColumn.ClientApproved,
        operator: SqlOperator.Eq,
        options: [
          { label: t`Yes`, value: "true" },
          { label: t`No`, value: "false" },
        ],
      }),
      new Filter({
        title: t`Date`,
        column: BudgetWhereColumn.Date,
        operator: SqlOperator.Between,
        dateRange: true,
        options: [],
      }),
    ],
    { onChange: () => this.fetchBudgetQuotes() },
  );

  constructor(public appStore: AppStore) {
    makeObservable(this);
  }

  @computed get fetching(): boolean {
    return this.fetcher.isLoading.value;
  }

  @computed get returnParams() {
    return `where=${this.where.asURLSearchParam.where}`;
  }

  @action.bound public async fetchBudgetQuotes() {
    Router.replace({
      query: {
        where: this.where.asURLSearchParam.where,
        orderBy: this.orderBy.asURLSearchParam.orderBy,
        first: this.pagination.asURLSearchParam.first,
        page: this.pagination.asURLSearchParam.page,
      },
    });

    const [data, error] = await this.fetcher.fetch({
      filters: {
        where: this.where.asWhereParam,
        page: this.pagination.asParams.page,
        first: this.pagination.asParams.first,
        orderBy: this.orderBy.asWhereParam,
      },
    });

    if (!data || error) return;

    this.setTableData(data);
  }

  @action public async setFilterOptions() {
    const { data } = await client
      .query<
        BudgetsFilterOptionsQuery,
        BudgetsFilterOptionsQueryVariables
      >(BudgetsFilterOptionsDocument, {})
      .toPromise();

    if (!data) return;

    this.where.filtersByColumn.get(BudgetWhereColumn.BrandId)?.setOptions(
      data.brandsSimpleMap.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    );
    this.where.filtersByColumn.get(BudgetWhereColumn.ProjectId)?.setOptions(
      data.projectsSimpleMap.map(({ id, title, code }) => ({
        value: id,
        label: code + " " + title,
      })),
    );
  }

  @action private setTableData(data: GetBudgetQuotesQuery): void {
    this.pagination.setFromPaginatorInfo(data.getBudgetQuotes.paginatorInfo);

    if (!data.getBudgetQuotes.data) return;
    this.tableData = [];

    for (const quote of data.getBudgetQuotes.data) {
      const budgetsCount = quote.budgets?.length ?? 0;

      if (budgetsCount === 0) continue;
      if (!quote.budgets?.[0]) continue;
      const row = new BudgetQuoteModel(quote, this.appStore);
      this.tableData.push(row);
    }
  }

  @computed get budgetTypeFilter() {
    return this.where.filtersByColumn.get(BudgetWhereColumn.Type);
  }
}
