import { Box, HStack, Text } from "@chakra-ui/layout";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { Card, DataTable, LoadingOverlay } from "@src/components/ui-kit";
import { Pagination } from "@src/components/ui-kit/table/pagination";
import { EXCHANGE_RATE_DENOMINATION } from "@src/stores/forms/SettingsModalState/FinancialSettingsState";
import { FiltersPanel } from "@src/utils/components/filters/FiltersPanel";
import { convertToWorkspaceCurrency } from "@src/utils/currency";
import { currency, nominate } from "@src/utils/formatters";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";

export const MatchPaymentModal = observer(function MatchPaymentModal() {
  const { matchPaymentModalStore: store, workspaceStore } = useStore();
  return (
    <Modal
      isOpen={store.disclosure.isOpen}
      onClose={store.disclosure.onClose}
      scrollBehavior="inside"
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent minW="85vw" h="85vh" minH="85vh">
        <ModalHeader display="inline-flex">
          <Text fontWeight="inherit">
            <Trans>Match payment</Trans>
          </Text>
          {/*
          store.selectedAccountMove && (
            <Text
              color="gray.600"
              fontSize="md"
              textAlign="center"
              marginY="auto"
            >
              &nbsp;
              <Trans>- Paid -</Trans>
              &nbsp;
              {currency.formatByWorkspace(store.selectedAccountMove.amount)}
            </Text>
          )
          */}
          <ModalCloseButton isDisabled={store.mutator.isLoading.value} />
        </ModalHeader>
        <ModalBody>
          {store.selectedDocument && (
            <Card mb="4">
              <Table>
                <Thead>
                  <Tr>
                    <Th>
                      <Trans>Document no</Trans>
                    </Th>
                    <Th>
                      <Trans>Amount</Trans>
                    </Th>
                    {store.moveHasForeignCurrency ? (
                      <>
                        <Th>
                          <Trans>Exchange rate</Trans>
                        </Th>
                        <Th>
                          <Trans>Converted amount</Trans>
                        </Th>
                      </>
                    ) : (
                      <></>
                    )}
                    <Th>
                      <Trans>Balance</Trans>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>{store.selectedDocument.number}</Td>
                    <Td>
                      {currency.formatByCurrency(
                        store.selectedDocument.amount,
                        store.selectedDocument.currency,
                      )}
                    </Td>
                    {store.moveHasForeignCurrency ? (
                      <>
                        <Td>
                          {workspaceStore.settings?.currency && (
                            <Text>
                              {currency.formatByCurrency(
                                100,
                                workspaceStore.settings?.currency,
                              )}
                              &nbsp;=&nbsp;
                              {currency.formatByCurrency(
                                nominate(
                                  store.selectedDocument.exchangeRate,
                                  EXCHANGE_RATE_DENOMINATION - 2,
                                ),
                                store.selectedDocument.currency,
                              )}
                            </Text>
                          )}
                        </Td>
                        <Td>
                          {currency.formatByWorkspace(
                            convertToWorkspaceCurrency(
                              store.selectedDocument.amount,
                              {
                                exchange_rate:
                                  store.selectedDocument.exchangeRate,
                              },
                            ),
                          )}
                        </Td>
                      </>
                    ) : (
                      <></>
                    )}
                    <Td color={store.balanceColor}>
                      {currency.formatByWorkspace(store.balance)}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Card>
          )}
          <DataTable>
            <DataTable.NavBar>
              <Box py="4">
                <FiltersPanel
                  onSearch={store.handleSearch}
                  filters={store.where}
                />
              </Box>
            </DataTable.NavBar>
            <LoadingOverlay
              position="absolute"
              isLoading={store.fetcher.isLoading.value}
            >
              <DataTable.Body
                columns={store.columns}
                data={store.tableData}
                tableKey={store.tableKey}
                selected={store.selectedIds}
                onSelect={store.handleSelect}
                selectable
              />
            </LoadingOverlay>
            <Pagination store={store.pagination} />
          </DataTable>
        </ModalBody>
        <ModalFooter>
          <HStack justify="end" spacing="2">
            <Button
              colorScheme="grey"
              isLoading={store.mutator.isLoading.value}
              onClick={store.disclosure.onClose}
              variant="outline"
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              isLoading={store.mutator.isLoading.value}
              onClick={store.handleConfirm}
            >
              <Trans>Match</Trans>
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
