import { Box, Center, Heading, Text, VStack } from "@chakra-ui/layout";
import { t, Trans } from "@lingui/macro";
import { ButtonLink, Select } from "@src/components/ui-kit";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";

export const Pipedrive = observer(function Pipedrive() {
  const {
    workspaceStore,
    pipedriveStore,
    settingsModalStore: { pipedriveSettingsStore },
  } = useStore();
  const form = pipedriveSettingsStore.form.$;

  return workspaceStore.settings?.pipedriveEnabled ? (
    <Box>
      <Heading as="h3" textAlign="left" size="md" mt="4" py="2">
        <Trans>Pipedrive integration settings</Trans>
      </Heading>
      <VStack spacing="2">
        <Select
          label={t`Pipedrive stage`}
          options={pipedriveStore.pipedriveStageOptions}
          value={form.pipedrive_stage_id.value}
          onChange={form.pipedrive_stage_id.onChange}
          error={form.pipedrive_stage_id.error}
          isLoading={pipedriveStore.optionsFetcher.isLoading.value}
        />
        <Select
          label={t`Project stage`}
          options={pipedriveStore.projectStageOptions}
          value={form.project_stage_id.value}
          onChange={form.project_stage_id.onChange}
          error={form.project_stage_id.error}
          isLoading={pipedriveStore.optionsFetcher.isLoading.value}
        />
        <Select
          label={t`Project billing category`}
          options={pipedriveSettingsStore.billableOptions}
          value={form.project_billable.value}
          onChange={form.project_billable.onChange}
          error={form.project_billable.error}
        />
      </VStack>
    </Box>
  ) : (
    <Center h="full">
      <Text fontWeight="bold" fontSize="lg">
        <strong>
          <Trans>Pipedrive isn't set up yet, please</Trans>
          &nbsp;
          {pipedriveSettingsStore.initializationUrl && (
            <ButtonLink
              href={pipedriveSettingsStore.initializationUrl}
              isExternal
              target="_blank"
              my="2"
            >
              <Trans>initialize</Trans>
            </ButtonLink>
          )}
          &nbsp;<Trans>first.</Trans>
        </strong>
      </Text>
    </Center>
  );
});
