import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { CollapsibleProps } from "./Body";
import Cell from "./Cell";
import CollapseRowButton from "./CollapseRowButton";
import { TColumn, TRow } from "./types";

class CollapsibleGroup<T> {
  @observable.ref open: boolean;
  subRows: T;

  constructor(open: boolean, subRows: T) {
    makeObservable(this);
    this.open = open;
    this.subRows = subRows;
  }

  @action.bound
  toggle(state: boolean) {
    this.open = state;
  }
}

interface SubRowsGroupProps<
  R extends TRow<T>,
  T extends { id: string },
  K = any,
> {
  row: R;
  columns: TColumn<R, K, T>[];
  groupBy: CollapsibleProps<R, T>["subRowsGroupBy"];
  groupLabel: CollapsibleProps<R, T>["subRowsGroupLabel"];
  selectable?: boolean;
  hideSelectCell?: boolean;
  hideCollapseCell?: boolean;
}

const SubRowsGroup = <R extends TRow<S>, S extends { id: string }>({
  row,
  columns,
  groupBy,
  groupLabel,
  selectable,
  hideSelectCell,
  hideCollapseCell,
}: SubRowsGroupProps<R, S>) => {
  const subRows = useMemo(() => {
    return row.__subRows!.reduce((r, subRow) => {
      if (!groupBy) return r;

      const group = String(subRow[groupBy]);

      if (r.has(group)) {
        const collapsibleGroup = r.get(group)!;
        r.set(
          group,
          new CollapsibleGroup(collapsibleGroup.open, [
            ...collapsibleGroup.subRows,
            subRow,
          ]),
        );
      } else {
        r.set(group, new CollapsibleGroup(true, [subRow]));
      }
      return r;
    }, new Map<string, CollapsibleGroup<NonNullable<typeof row.__subRows>>>());
  }, [row, groupBy]);

  return (
    <React.Fragment>
      {[...subRows.entries()].map(([groupKey, group]) => (
        <React.Fragment key={`${row.id}-${groupKey}`}>
          <tr
            className="Polaris-DataTable__TableRow Polaris-DataTable__TableSubRowsGroup"
            role="group"
          >
            <Cell type="td" width={46}>
              <CollapseRowButton open={group.open} onClick={group.toggle} />
            </Cell>
            <Cell
              type="td"
              colSpan={columns.length + +!!selectable}
              className="text-turquoiseDark font-semibold"
            >
              {groupLabel ? groupLabel(groupKey, row) : groupKey}
            </Cell>
          </tr>
          {group.open &&
            group.subRows.map((subRow) => (
              <tr
                key={subRow.id}
                className="Polaris-DataTable__TableRow Polaris-DataTable__TableSubRow"
                role="group"
              >
                {selectable && !hideSelectCell && <Cell type="td" />}
                {!hideCollapseCell && <Cell type="td" />}
                {columns.map((col) => (
                  <Cell
                    key={col.key}
                    type="td"
                    colSpan={col.subRowColSpan}
                    align={col.align}
                    width={col.width}
                  >
                    {col.renderSubRow && col.renderSubRow(subRow, row)}
                  </Cell>
                ))}
              </tr>
            ))}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default observer(SubRowsGroup);
