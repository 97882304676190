import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { PaginatorInfo } from "@src/__generated__/graphql";
import {
  SelectInternal,
  SelectProps,
} from "@src/components/ui-kit/Select/Select";
import { IOption } from "@src/components/ui-kit/Select/types";
import {
  GroupBase,
  MenuListProps,
  SelectInstance,
  SetValueAction,
  chakraComponents,
} from "chakra-react-select";
import { uniqueId } from "lodash";
import { observer } from "mobx-react-lite";
import { Ref, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { withAsyncPaginate, wrapMenuList } from "react-select-async-paginate";
const DEFAULT_PER_PAGE = 5;

export interface LargeSelectProps extends SelectProps {
  perPage?: number;
  defaultOptions?: boolean | IOption[];

  loadOptions?: (
    page: number,
    perPage: number,
    searchPhrase: string,
  ) => Promise<{
    data: IOption[];
    paginatorInfo: PaginatorInfo | undefined;
  }>;
}
export type LargeSelectRef = {
  clearOptions: () => void;
  setValue: (val: unknown, action: SetValueAction, option?: unknown) => void;
  focus: () => void;
};

const ChakraAsyncPaginate = withAsyncPaginate(SelectInternal as any);

export const LargeSelect = observer<LargeSelectProps, LargeSelectRef>(
  forwardRef<LargeSelectRef, LargeSelectProps>(function LargeSelect(
    {
      loadOptions,
      perPage = DEFAULT_PER_PAGE,
      defaultOptions = true,
      error,
      label,
      ...props
    },
    ref,
  ) {
    const selectRef = useRef<SelectInstance>(null);
    const [cacheUniq, setCacheUniq] = useState<string | undefined>(undefined);
    const [passedOptionsValues, setPassedOptionsValues] = useState(
      new Set<string>(),
    );
    const _loadOptions = async (
      search: string,
      _: readonly unknown[],
      additional: { page: number } | undefined,
    ) => {
      const data = await loadOptions?.(additional?.page ?? 1, perPage, search);
      const options: IOption[] = [];

      if (!additional || additional.page === 1) {
        for (const option of props.options) {
          if ("options" in option) continue;
          options.push(option);
          handleAddOptionValue(option.value);
        }

        if (Array.isArray(defaultOptions)) {
          for (const option of defaultOptions) {
            options.push(option);
            handleAddOptionValue(option.value);
          }
        }
      }

      if (data?.data) {
        for (const option of data?.data) {
          if (passedOptionsValues.has(option.value)) continue;
          options.push(option);
        }
      }

      return {
        options,
        hasMore: data?.paginatorInfo?.hasMorePages,
        additional: {
          page: (additional?.page ?? 1) + 1,
        },
      };
    };

    const handleAddOptionValue = (value: string) => {
      setPassedOptionsValues((prev) => {
        prev.add(value);
        return prev;
      });
    };

    const CustomMenuList = (
      menuProps: MenuListProps<unknown, boolean, GroupBase<unknown>>,
    ) => (
      <chakraComponents.MenuList {...menuProps}>
        {!!props.topExtraContent && props.topExtraContent}
        {menuProps.children}
      </chakraComponents.MenuList>
    );

    const MenuList = wrapMenuList(CustomMenuList);

    useImperativeHandle(ref, () => ({
      clearOptions: () => {
        setCacheUniq(uniqueId("cacheUniq"));
      },
      setValue(value, action, option) {
        selectRef.current?.setValue(value, action, option);
      },
      focus: () => {
        selectRef.current?.focus();
      },
    }));

    return (
      <FormControl w="full" isInvalid={!!error}>
        {label && <FormLabel>{label}</FormLabel>}
        <ChakraAsyncPaginate
          {...props}
          key={`${cacheUniq}-${defaultOptions?.toString()}-${props.options.toString()}`}
          selectRef={
            selectRef as Ref<
              SelectInstance<unknown, boolean, GroupBase<unknown>>
            >
          }
          debounceTimeout={300}
          defaultOptions={defaultOptions}
          loadOptions={_loadOptions}
          additional={{
            page: 1,
          }}
          components={{
            MenuList,
          }}
        />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  }),
);
