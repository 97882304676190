import { ProjectStage } from "@src/__generated__/graphql";
import { IOption } from "@src/components/ui-kit";

export function projectStages(
  stages: ProjectStage[] | undefined | null,
): IOption[] {
  if (!stages) return [];
  return stages.map((stage) => ({
    value: stage.id,
    label: stage.name,
  }));
}
