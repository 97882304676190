import { Box, Center, Grid, GridItem, Text } from "@chakra-ui/layout";
import { Alert, AlertIcon, AlertTitle, Image } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { Card, Link } from "@src/components/ui-kit";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";

export const BanksTab = observer(function BanksTab() {
  const {
    settingsModalStore: { banksStore },
    workspaceStore,
  } = useStore();

  return (
    <Box mt="12">
      <Text mb="2">
        <strong>
          <Trans>
            By activating the connection, I agree that the company Tools Done
            Right s. r. o. (
          </Trans>
          <Link href="https://allfred.io/" isExternal target="_blank">
            Allfred.io
          </Link>
          <Trans>
            ) can request my bank transaction data up to 20 times per day
            instead of the default limit of 4 times per day.
          </Trans>
        </strong>
      </Text>
      {workspaceStore.settings?.bankApiActive && (
        <Alert mb="2" rounded="lg" status="error">
          <AlertIcon />
          <AlertTitle>
            <Trans>
              Bank is already connected. If you connect new bank, previous
              connection will be overwritten.
            </Trans>
          </AlertTitle>
        </Alert>
      )}
      <Text>
        <Trans>To activate the connection, click on your bank:</Trans>
      </Text>
      <Grid gridTemplateColumns="repeat(5, 1fr)" my="4" gap="4">
        {banksStore.banks.map((bank) => (
          <GridItem key={`${bank.api_id}-${bank.name}`}>
            <Card overflow="hidden" h="full">
              <Center h="full">
                <Link
                  variant="unstyled"
                  href={bank.url}
                  isExternal
                  target="_self"
                >
                  <Image
                    objectFit="contain"
                    alt={`${bank.name} logo`}
                    src={bank.logo}
                  />
                </Link>
              </Center>
            </Card>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
});
