import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  useBoolean,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";
import { AsyncOrSync } from "ts-essentials";

export interface ModalConfirmProps
  extends Omit<AlertDialogProps, "leastDestructiveRef" | "children"> {
  onConfirm: () => AsyncOrSync<unknown>;
  /**
   * @default true
   */
  closeOnConfirm?: boolean;
  isLoading?: boolean;
  cancelBtnTitle?: string;
  confirmBtnTitle?: string;
  heading?: string;
  destructive?: boolean;
  onClose: AlertDialogProps["onClose"];
  message?: React.ReactNode;
  disableConfirm?: boolean;
  hideCancelButton?: boolean;
}

const ModalConfirm: FunctionComponent<
  React.PropsWithChildren<ModalConfirmProps>
> = ({
  onConfirm,
  closeOnConfirm,
  onClose,
  isOpen,
  message,
  isLoading,
  destructive,
  cancelBtnTitle,
  confirmBtnTitle,
  heading,
  children,
  disableConfirm,
  hideCancelButton,
  ...props
}) => {
  const [confirmLoading, setConfirmLoading] = useBoolean(false);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const onConfirmWrapper = async () => {
    setConfirmLoading.on();
    await onConfirm();
    setConfirmLoading.off();
    if (closeOnConfirm !== false) {
      onClose();
    }
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      size="lg"
      trapFocus={false}
      {...props}
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>
          {heading ? heading : t`Confirm action`}
        </AlertDialogHeader>
        <AlertDialogCloseButton />

        <AlertDialogBody>
          {children ? (
            children
          ) : message ? (
            message
          ) : (
            <p>{<Trans>Are you sure?</Trans>}</p>
          )}
        </AlertDialogBody>

        <AlertDialogFooter>
          {!hideCancelButton && (
            <Button
              ref={cancelRef}
              colorScheme="grey"
              isDisabled={isLoading || confirmLoading}
              onClick={onClose}
              variant="outline"
            >
              {cancelBtnTitle ? cancelBtnTitle : t`No`}
            </Button>
          )}
          <Button
            ml={3}
            colorScheme={destructive ? "red" : "blue"}
            isDisabled={disableConfirm}
            isLoading={isLoading || confirmLoading}
            onClick={onConfirmWrapper}
          >
            {confirmBtnTitle ? confirmBtnTitle : t`Yes`}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default observer(ModalConfirm);
