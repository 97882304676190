import {
  Badge,
  BadgeProps,
  Button,
  HStack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import { captureEvent } from "@sentry/nextjs";
import {
  AccountMoveFragment,
  BankAccountMovesDocument,
  BankAccountMovesQuery,
  BankAccountMovesQueryVariables,
  BankAccountMoveStatusEnum,
  BankAccountsForPaymentsDocument,
  BankAccountsForPaymentsQuery,
  BankAccountsForPaymentsQueryVariables,
  BankAccountWhereColumn,
  BankDocumentTypeEnum,
  ExcludeAccountMoveDocument,
  ExcludeAccountMoveMutation,
  ExcludeAccountMoveMutationVariables,
  SqlOperator,
} from "@src/__generated__/graphql";
import { IOption, Link, TColumn } from "@src/components/ui-kit";
import { FetchHelper } from "@src/helpers/apollo/fetch";
import { MutationHelper } from "@src/helpers/apollo/mutation";
import { appStore, AppStore } from "@src/stores/AppStore";
import { Filter, Filters } from "@src/utils/components/filters/models";
import { convertToWorkspaceCurrency } from "@src/utils/currency";
import { currency } from "@src/utils/formatters";
import mapToOptions from "@src/utils/map-to-options";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { upperFirst } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { LinkProps } from "next/link";
import { AddManualMoveModalStore } from "../add-manual-move-modal/store";
import { BankMatchPaymentModalStore } from "../match-payment-modal/store";

export class BankStore {
  readonly tableKey = "bank-listing-table";

  fetcher = new FetchHelper<
    BankAccountMovesQuery,
    BankAccountMovesQueryVariables
  >(BankAccountMovesDocument);
  bankAccountsFetcher = new FetchHelper<
    BankAccountsForPaymentsQuery,
    BankAccountsForPaymentsQueryVariables
  >(BankAccountsForPaymentsDocument);
  excludeMutator = new MutationHelper<
    ExcludeAccountMoveMutation,
    ExcludeAccountMoveMutationVariables
  >(ExcludeAccountMoveDocument);
  @observable.ref loadingRowId: string | undefined;

  @observable.ref searchTerm = "";
  where = new Filters<BankAccountWhereColumn>(
    [
      new Filter({
        title: t`Bank Account`,
        column: BankAccountWhereColumn.BankAccountId,
        operator: SqlOperator.In,
        options: [],
      }),
    ],
    {
      onChange: () => this.fetchData(),
    },
  );
  pagination = new PaginationState(`${this.tableKey}-pagination`, {
    onChangePagination: () => this.fetchData(),
  });

  @observable.ref bankAccountOptions: IOption[] = [];
  @observable tableData: AccountMoveFragment[] = [];

  constructor(
    private appStore: AppStore,
    public matchPaymentModalStore: BankMatchPaymentModalStore,
    public addManualMoveModalStore: AddManualMoveModalStore,
  ) {
    makeObservable(this);
    this.fetchData();
    this.fetchAccountOptions();

    this.where.filtersByColumn
      .get(BankAccountWhereColumn.BankAccountId)
      ?.setOptions(
        mapToOptions.companiesToBankAccountOptions(
          appStore.workspaceStore.companies,
        ),
      );
  }

  private static getStatusBadgeColorScheme(
    status: BankAccountMoveStatusEnum,
  ): BadgeProps["colorScheme"] {
    switch (status) {
      case BankAccountMoveStatusEnum.Paired:
        return "green";
      case BankAccountMoveStatusEnum.NotPaired:
        return "red";
      case BankAccountMoveStatusEnum.ManualPaired:
        return "orange";
      default:
        return "gray";
    }
  }

  private static getUrlForDocument(
    document: NonNullable<AccountMoveFragment["documents"]>[0],
  ): LinkProps["href"] {
    return {
      pathname:
        document.document_type === BankDocumentTypeEnum.Expense
          ? "/expenses/edit"
          : document.document_type === BankDocumentTypeEnum.OutgoingInvoice
            ? "/invoices/outgoing-invoices/detail"
            : "/invoices/proforma-invoices/detail",
      query: { id: document.document_id },
    };
  }

  static getColumnsWithoutActions(hasForeignCurrency: boolean) {
    const cols: TColumn<AccountMoveFragment, unknown>[] = [
      {
        key: "status",
        header: () => t`Status`,
        render: (row) => (
          <Badge colorScheme={this.getStatusBadgeColorScheme(row.status)}>
            {row.status.replaceAll("_", " ")}
          </Badge>
        ),
      },
      {
        key: "amount",
        header: () => t`Amount`,
        render: (row) => currency.formatByCurrency(row.amount, row.currency),
      },
      {
        key: "converted-amount",
        header: () => t`Converted amount`,
        hide: !hasForeignCurrency,
        render: (row) =>
          row.currency.id !== appStore.workspaceStore.settings?.currency.id
            ? currency.formatByWorkspace(
                convertToWorkspaceCurrency(row.amount, row.currency),
              )
            : null,
      },
      {
        key: "reference",
        header: () => t`Reference`,
        render: (row) => row.reference,
      },
    ];

    if (appStore.workspaceStore.settings?.country.code === "SK") {
      cols.push(
        {
          key: "variable-symbol",
          header: () => t`Variable symbol`,
          render: (row) => row.vs,
        },
        {
          key: "specific-symbol",
          header: () => t`Specific symbol`,
          render: (row) => row.ss,
        },
        {
          key: "constant-symbol",
          header: () => t`Constant symbol`,
          render: (row) => row.ks,
        },
      );
    }

    cols.push(
      { key: "note", header: () => t`Note`, render: (row) => row.note },
      {
        key: "type",
        header: () => t`Match`,
        render: (row) => <Badge>{row.type.replaceAll("-", "")}</Badge>,
      },
      {
        key: "paired-documents",
        header: () => t`Paired documents`,
        render: (row) =>
          row.documents ? (
            <HStack spacing="0">
              {row.documents.map((document, index) => (
                <Tooltip
                  key={`paired-document-${document.id}-${index}`}
                  label={upperFirst(
                    document.document_type.replaceAll("_", " ").toLowerCase(),
                  )}
                  placement="top"
                >
                  <Text>
                    <Link href={this.getUrlForDocument(document)}>
                      {document.document_no}
                    </Link>
                    {index < (row.documents?.length ?? 1) - 1 && <>,&nbsp;</>}
                  </Text>
                </Tooltip>
              ))}
            </HStack>
          ) : null,
      },
    );

    return cols;
  }

  @computed get columns() {
    const cols = BankStore.getColumnsWithoutActions(
      this.tableData.some(
        (item) =>
          item.currency.id !==
          this.appStore.workspaceStore.settings?.currency.id,
      ),
    );

    cols.push({
      key: "actions",
      disableVisibilityControl: true,
      render: (row) => (
        <HStack justify="end" spacing="2">
          {row.status === BankAccountMoveStatusEnum.NotPaired && (
            <Button
              colorScheme="red"
              isLoading={this.loadingRowId === row.id}
              onClick={() => {
                this.excludeAccountMove(row.id);
              }}
              size="sm"
              variant="outline"
            >
              <Trans>Don't match</Trans>
            </Button>
          )}
          <Button
            colorScheme="grey"
            isLoading={this.loadingRowId === row.id}
            onClick={() => {
              this.matchPaymentModalStore.disclosure.onOpen({
                accountMove: row,
                onConfirm: () => {
                  this.pagination.resetPage();
                },
              });
            }}
            size="sm"
            variant="outline"
          >
            {row.status === BankAccountMoveStatusEnum.NotPaired ||
            row.status === BankAccountMoveStatusEnum.Excluded ? (
              <Trans>Match</Trans>
            ) : (
              <Trans>Match again</Trans>
            )}
          </Button>
        </HStack>
      ),
    });

    return cols;
  }

  @action.bound setSearch(newValue: string): void {
    this.searchTerm = newValue;
    this.fetchData();
  }

  @action.bound async fetchAccountOptions() {
    const [data, error] = await this.bankAccountsFetcher.fetch();

    if (error) return;

    this.bankAccountOptions = mapToOptions.paymentBankAccounts(
      data.bankAccounts ?? [],
    );
    this.addManualMoveModalStore.setBankAccountOptions(this.bankAccountOptions);
  }

  @action.bound async fetchData() {
    const [data, error] = await this.fetcher.fetch(
      {
        filters: {
          where: this.where.asWhereParam,
          search: this.searchTerm,
        },
        page: this.pagination.asParams.page,
        first: this.pagination.asParams.first,
      },
      undefined,
    );

    if (error || !data.bankAccountMoves) return;

    this.tableData = data.bankAccountMoves.data;
    this.pagination.setFromPaginatorInfo(data.bankAccountMoves.paginatorInfo);
  }

  @action.bound async excludeAccountMove(accountMoveId: string) {
    this.loadingRowId = accountMoveId;
    const [data, error] = await this.excludeMutator.mutate({
      accountMoveId: accountMoveId,
    });

    this.loadingRowId = undefined;
    if (error || !data.excludeBankAccountMove) return;
    this.updateRow(data.excludeBankAccountMove);
  }

  @action.bound updateRow(updatedRow: AccountMoveFragment) {
    let rowIndex: number | undefined;

    const rowCount = this.tableData.length;
    for (let i = 0; i < rowCount; i++) {
      const row = this.tableData[i];
      if (row.id !== updatedRow.id) continue;
      rowIndex = i;
      break;
    }

    if (!rowIndex) {
      this.appStore.UIStore.toast({
        status: "error",
        title: t`Couldn't find row to be updated, please try again.`,
      });
      captureEvent({
        message: "FE: Couldn't find row to be updated.",
      });
      return;
    }

    this.tableData.splice(rowIndex, 1, updatedRow);
  }
}
