import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Radio,
  Switch,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import { FormRow, ModalConfirm, Select } from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { Fragment, FunctionComponent } from "react";

export const Financial: FunctionComponent = observer(function Financial() {
  const {
    settingsModalStore: { financialStore },
    workspaceStore,
  } = useStore();

  return (
    <Fragment>
      <TableContainer>
        <Table size="sm" variant="simple">
          <TableCaption paddingInlineStart="0" placement="top">
            <Heading as="h3" textAlign="left" size="md">
              <Trans>VAT rates</Trans>
            </Heading>
          </TableCaption>
          <Thead>
            <Tr>
              <Th textAlign="center">
                <Trans>Default</Trans>
              </Th>
              <Th w="99%">
                <Trans>Amount</Trans>
              </Th>
              <Th textAlign="center">
                <Trans>Actions</Trans>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {financialStore.form.form.$.vatRates.$.map((vatRate) => (
              <Tr key={vatRate.internalId}>
                <Td>
                  <Flex as="label" align="center" justify="center">
                    <Radio
                      isChecked={vatRate.$.default.value}
                      name="default"
                      onChange={(event) => {
                        if (event.target.checked) {
                          financialStore.form.makeDefault(vatRate);
                        }
                      }}
                    />
                  </Flex>
                </Td>
                <Td>
                  <FormControl isInvalid={vatRate.$.amount.hasError}>
                    <Input
                      inputMode="decimal"
                      isDisabled={Boolean(vatRate.id)}
                      onChange={(event) =>
                        vatRate.$.amount.onChange(event.target.value)
                      }
                      type="number"
                      value={vatRate.$.amount.value}
                    />
                    <FormErrorMessage>
                      <Trans>VAT rate amount is required</Trans>
                    </FormErrorMessage>
                  </FormControl>
                </Td>
                <Td>
                  <Flex align="center" justify="center" gap="3">
                    <Tooltip
                      hasArrow
                      label={
                        financialStore.form.form.$.vatRates.$.length === 1
                          ? t`You cannot remove the last VAT rate`
                          : t`Remove this VAT rate`
                      }
                    >
                      <IconButton
                        aria-label={t`Delete this VAT rate`}
                        icon={<Icon name="trash-03" />}
                        isDisabled={
                          financialStore.form.form.$.vatRates.$.length === 1
                        }
                        onClick={() =>
                          financialStore.form.requestVatRateRemoval(vatRate)
                        }
                        variant="ghost"
                      />
                    </Tooltip>
                    <ModalConfirm
                      heading={t`Confirm removal of VAT rate`}
                      onClose={() =>
                        (financialStore.form.vatRateToDelete = undefined)
                      }
                      isOpen={financialStore.form.vatRateToDelete === vatRate}
                      onConfirm={() =>
                        financialStore.form.removeVatRate(vatRate)
                      }
                    >
                      <Trans>
                        Removal of this VAT rate will keep past uses untouched,
                        but you will not be able to use it in the future
                        anymore. Do you want to remove this VAT rate?
                      </Trans>
                    </ModalConfirm>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th colSpan={3}>
                <Flex justify="end" paddingBlock="3">
                  <Button
                    leftIcon={<Icon name="plus" />}
                    onClick={() => financialStore.form.addNewVatRate()}
                    variant="outline"
                  >
                    <Trans>Add new VAT rate</Trans>
                  </Button>
                </Flex>
              </Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <TableContainer>
        <Table size="sm" variant="simple">
          <TableCaption paddingInlineStart="0" placement="top">
            <Heading as="h3" textAlign="left" size="md">
              <Trans>Currencies & exchange rates</Trans>
            </Heading>
          </TableCaption>
          <Thead>
            <Tr>
              <Th>
                <Trans>Currency</Trans>
              </Th>
              <Th textAlign="center">
                <Trans>Automatic from ECB</Trans>
              </Th>
              <Th w="99%">
                <Trans>Manual exchange rate</Trans>
              </Th>
              <Th textAlign="center">
                <Trans>Actions</Trans>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {financialStore.form.form.$.currencies.$.map((currency) => (
              <Tr key={currency.internalId}>
                <Td>
                  <Tooltip
                    isDisabled={currency.canBeRemoved}
                    label={t`Saved currency can't be changed.`}
                  >
                    <Flex as="label" align="center" justify="center" w="90px">
                      <Select
                        isDisabled={!currency.canBeRemoved}
                        value={currency.$.code.value}
                        onChange={currency.$.code.onChange}
                        error={currency.$.code.error}
                        options={financialStore.form.getFilteredCurrencyOptions(
                          currency.$.code.value,
                        )}
                      />
                    </Flex>
                  </Tooltip>
                </Td>
                {currency.$.code.$ !==
                workspaceStore.settings?.currency.code ? (
                  <>
                    <Td>
                      <Flex align="center" justify="center">
                        <Checkbox
                          isChecked={currency.$.isAuto.value}
                          name="default"
                          onChange={({ target }) => {
                            currency.handleSetAuto(target.checked);
                          }}
                        />
                      </Flex>
                    </Td>
                    <Td>
                      <InputGroup>
                        <InputLeftAddon>
                          1&nbsp;{workspaceStore.settings?.currency.code}&nbsp;=
                        </InputLeftAddon>
                        <Input
                          inputMode="decimal"
                          isDisabled={currency.isExchangeRateInputDisabled}
                          onChange={({ target }) =>
                            currency.$.exchange_rate.onChange(target.value)
                          }
                          placeholder={
                            currency.isExchangeRateInputDisabled
                              ? t`Updated daily`
                              : t`Manual exchange rate`
                          }
                          type="number"
                          value={currency.$.exchange_rate.value}
                        />
                        <InputRightAddon>
                          {currency.$.code.value}
                        </InputRightAddon>
                      </InputGroup>
                    </Td>
                  </>
                ) : (
                  <>
                    <Td colSpan={2}>
                      <Text color="gray" textAlign="center">
                        <Trans>System currency can't be changed</Trans>
                      </Text>
                    </Td>
                  </>
                )}
                <Td>
                  <Flex align="center" justify="center" gap="3">
                    <Tooltip
                      hasArrow
                      label={
                        currency.canBeRemoved
                          ? t`Remove this currency`
                          : t`Can't remove already saved currency.`
                      }
                    >
                      <IconButton
                        aria-label={t`Delete this currency`}
                        icon={<Icon name="trash-03" />}
                        isDisabled={!currency.canBeRemoved}
                        onClick={() => {
                          financialStore.form.removeCurrency(
                            currency.internalId,
                          );
                        }}
                        variant="ghost"
                      />
                    </Tooltip>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Td colSpan={4}>
                <HStack justify="space-between" paddingBlock="3">
                  <Text color="gray">
                    <Trans>
                      The exchange rate set in the client detail takes
                      precedence over any rates set above.
                    </Trans>
                  </Text>
                  <Button
                    leftIcon={<Icon name="plus" />}
                    onClick={() => financialStore.form.addNewCurrency()}
                    variant="outline"
                  >
                    <Trans>Add new currency</Trans>
                  </Button>
                </HStack>
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <TableContainer>
        <Table size="sm" variant="simple">
          <TableCaption placement="top">
            <Heading as="h3" textAlign="left" size="md">
              <Trans>Revenue account classification</Trans>
            </Heading>
          </TableCaption>
          <Thead>
            <Tr>
              <Th minW="240px">
                <Trans>Number</Trans>
              </Th>
              <Th w="99%">
                <Trans>Title</Trans>
              </Th>
              <Th minW="180px" textAlign="center">
                <Trans>Default</Trans>
              </Th>
              <Th textAlign="center">
                <Trans>Actions</Trans>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {financialStore.form.form.$.billingAccountingAccounts.$.map(
              (account) => (
                <Tr key={account.internalId}>
                  <Td verticalAlign="top">
                    <FormControl isInvalid={account.$.account_number.hasError}>
                      <Input
                        onChange={(event) =>
                          account.$.account_number.onChange(event.target.value)
                        }
                        type="text"
                        value={account.$.account_number.value}
                      />
                      <FormErrorMessage>
                        {account.$.account_number.error}
                      </FormErrorMessage>
                    </FormControl>
                  </Td>
                  <Td verticalAlign="top">
                    <FormControl isInvalid={account.$.title.hasError}>
                      <Input
                        onChange={(event) =>
                          account.$.title.onChange(event.target.value)
                        }
                        type="text"
                        value={account.$.title.value}
                      />
                      <FormErrorMessage>
                        <Trans>Title is required</Trans>
                      </FormErrorMessage>
                    </FormControl>
                  </Td>
                  <Td verticalAlign="top">
                    <Select
                      isMulti
                      value={account.$.budget_categories.value}
                      options={financialStore.form.budgetCategoryOptions}
                      onChange={account.$.budget_categories.onChange}
                      asPortal
                    />
                  </Td>
                  <Td>
                    <Flex align="center" justify="center" gap="3">
                      <Tooltip
                        hasArrow
                        label={
                          financialStore.form.form.$.billingAccountingAccounts.$
                            .length === 1
                            ? t`You cannot remove the last account`
                            : t`Remove this account`
                        }
                      >
                        <IconButton
                          aria-label={t`Delete this account`}
                          icon={<Icon name="trash-03" />}
                          isDisabled={
                            financialStore.form.form.$.billingAccountingAccounts
                              .$.length === 1
                          }
                          onClick={() =>
                            financialStore.form.requestBillingAccountingAccountRemoval(
                              account,
                            )
                          }
                          variant="ghost"
                        />
                      </Tooltip>
                      <ModalConfirm
                        heading={t`Confirm removal of accounting account`}
                        onClose={() =>
                          (financialStore.form.billingAccountingAccountToDelete =
                            undefined)
                        }
                        isOpen={
                          financialStore.form
                            .billingAccountingAccountToDelete === account
                        }
                        onConfirm={() =>
                          financialStore.form.removeBillingAccountingAccount(
                            account,
                          )
                        }
                      >
                        <Trans>
                          Removal of this accounting account will keep past uses
                          untouched, but you will not be able to use it in the
                          future anymore. Do you want to remove this account?
                        </Trans>
                      </ModalConfirm>
                    </Flex>
                  </Td>
                </Tr>
              ),
            )}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th colSpan={3}>
                <Flex justify="end" paddingBlock="3">
                  <Button
                    leftIcon={<Icon name="plus" />}
                    onClick={() =>
                      financialStore.form.addNewBillingAccountingAccount()
                    }
                    variant="outline"
                  >
                    <Trans>Add new accounting account</Trans>
                  </Button>
                </Flex>
              </Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <TableContainer>
        <Table size="sm" variant="simple">
          <TableCaption placement="top">
            <Heading as="h3" textAlign="left" size="md">
              <Trans>Expenses account classification</Trans>
            </Heading>
          </TableCaption>
          <Thead>
            <Tr>
              <Th minW="280px">
                <Trans>Number</Trans>
              </Th>
              <Th w="99%">
                <Trans>Title</Trans>
              </Th>
              <Th textAlign="center">
                <Trans>Actions</Trans>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {financialStore.form.form.$.expensesAccountingAccounts.$.map(
              (account) => (
                <Tr key={account.internalId}>
                  <Td verticalAlign="top">
                    <FormControl isInvalid={account.$.account_number.hasError}>
                      <Input
                        onChange={(event) =>
                          account.$.account_number.onChange(event.target.value)
                        }
                        type="text"
                        value={account.$.account_number.value}
                      />
                      <FormErrorMessage>
                        {account.$.account_number.error}
                      </FormErrorMessage>
                    </FormControl>
                  </Td>
                  <Td verticalAlign="top">
                    <FormControl isInvalid={account.$.title.hasError}>
                      <Input
                        onChange={(event) =>
                          account.$.title.onChange(event.target.value)
                        }
                        type="text"
                        value={account.$.title.value}
                      />
                      <FormErrorMessage>
                        <Trans>Title is required</Trans>
                      </FormErrorMessage>
                    </FormControl>
                  </Td>
                  <Td>
                    <Flex align="center" justify="center" gap="3">
                      <Tooltip
                        hasArrow
                        label={
                          financialStore.form.form.$.expensesAccountingAccounts
                            .$.length === 1
                            ? t`You cannot remove the last account`
                            : t`Remove this account`
                        }
                      >
                        <IconButton
                          aria-label={t`Delete this account`}
                          icon={<Icon name="trash-03" />}
                          isDisabled={
                            financialStore.form.form.$
                              .expensesAccountingAccounts.$.length === 1
                          }
                          onClick={() =>
                            financialStore.form.requestExpensesAccountingAccountRemoval(
                              account,
                            )
                          }
                          variant="ghost"
                        />
                      </Tooltip>
                      <ModalConfirm
                        heading={t`Confirm removal of accounting account`}
                        onClose={() =>
                          (financialStore.form.expensesAccountingAccountToDelete =
                            undefined)
                        }
                        isOpen={
                          financialStore.form
                            .expensesAccountingAccountToDelete === account
                        }
                        onConfirm={() =>
                          financialStore.form.removeExpensesAccountingAccount(
                            account,
                          )
                        }
                      >
                        <Trans>
                          Removal of this accounting account will keep past uses
                          untouched, but you will not be able to use it in the
                          future anymore. Do you want to remove this account?
                        </Trans>
                      </ModalConfirm>
                    </Flex>
                  </Td>
                </Tr>
              ),
            )}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th colSpan={3}>
                <Flex justify="end" paddingBlock="3">
                  <Button
                    leftIcon={<Icon name="plus" />}
                    onClick={() =>
                      financialStore.form.addNewExpensesAccountingAccount()
                    }
                    variant="outline"
                  >
                    <Trans>Add new accounting account</Trans>
                  </Button>
                </Flex>
              </Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <Heading as="h3" mb="2" textAlign="left" size="md">
        <Trans>Invoices options</Trans>
      </Heading>
      <FormRow title={<Trans>Show unit price on invoices by default</Trans>}>
        <Switch
          isChecked={financialStore.form.form.$.showUnitPrice.value}
          name="financial_show_unit_price"
          onChange={({ target }) =>
            financialStore.form.form.$.showUnitPrice.onChange(target.checked)
          }
        />
      </FormRow>
      <FormRow
        title={
          <Trans>Show account classification on invoices by default</Trans>
        }
      >
        <Switch
          isChecked={financialStore.form.form.$.showBillingAccounting.value}
          name="financial_billing_accounting"
          onChange={({ target }) =>
            financialStore.form.form.$.showBillingAccounting.onChange(
              target.checked,
            )
          }
        />
      </FormRow>
      {workspaceStore.settings?.country.code === "CZ" && (
        <>
          <Heading as="h3" mb="2" textAlign="left" size="md">
            <Trans>Contractor options</Trans>
          </Heading>
          <FormRow title={<Trans>Show bank account number and code</Trans>}>
            <Switch
              isChecked={
                financialStore.form.form.$.show_full_bank_account_information
                  .value
              }
              name="financial_show_unit_price"
              onChange={({ target }) =>
                financialStore.form.form.$.show_full_bank_account_information.onChange(
                  target.checked,
                )
              }
            />
          </FormRow>
        </>
      )}
    </Fragment>
  );
});
