import { captureEvent } from "@sentry/nextjs";
import {
  PipedriveDataDocument,
  PipedriveDataQuery,
  PipedriveDataQueryVariables,
  ProjectStageFragment,
  UpdateProjectStageDocument,
  UpdateProjectStageMutation,
  UpdateProjectStageMutationVariables,
} from "@src/__generated__/graphql";
import { IOption } from "@src/components/ui-kit";
import { FetchHelper } from "@src/helpers/apollo/fetch";
import { MutationHelper } from "@src/helpers/apollo/mutation";
import { AppStore } from "@src/stores/AppStore";
import mapToOptions from "@src/utils/map-to-options";
import { action, computed, makeObservable, observable } from "mobx";

export class PipedriveStore {
  @observable.ref pipedriveStageOptions: IOption[] = [];
  @observable.ref projectStages: ProjectStageFragment[] = [];

  optionsFetcher = new FetchHelper<
    PipedriveDataQuery,
    PipedriveDataQueryVariables
  >(PipedriveDataDocument);

  projectStageMutator = new MutationHelper<
    UpdateProjectStageMutation,
    UpdateProjectStageMutationVariables
  >(UpdateProjectStageDocument);

  constructor(private appStore: AppStore) {
    makeObservable(this);
  }

  @computed get projectStageOptions() {
    return mapToOptions.projectStages(this.projectStages);
  }

  @action.bound async fetchOptions() {
    if (
      this.pipedriveStageOptions.length > 0 ||
      this.projectStageOptions.length > 0
    )
      return;
    const [data, error] = await this.optionsFetcher.fetch();

    if (error) return;

    this.pipedriveStageOptions = mapToOptions.pipedriveStages(
      data.getPipedriveStages,
    );
    this.projectStages = data.projectStages;
  }

  async changeProjectStage(projectId: string, projectStageId: string) {
    const [data, error] = await this.projectStageMutator.mutate({
      projectId,
      projectStageId,
    });

    if (error) {
      this.appStore.UIStore.toast({
        status: "error",
      });
      captureEvent({
        message: "FE: Missing project ID for changing project stage.",
      });
      return null;
    }

    return data.updateProjectStage;
  }
}
