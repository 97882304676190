import { t } from "@lingui/macro";
import { WorkspaceSettingsQuery } from "@src/__generated__/urql-graphql";
import { IOption } from "@src/components/ui-kit";
import { action, makeObservable, observable } from "mobx";
import { AppStore } from "../AppStore";
import { PipedriveSettingsState } from "../forms/SettingsModalState/pipedrive-settings-state";

export class PipedriveSettingsStore {
  billableOptions: IOption[] = [];
  @observable.ref initializationUrl: string | null = null;

  constructor(
    private appStore: AppStore,
    public form: PipedriveSettingsState,
  ) {
    makeObservable(this);
  }

  @action.bound init(data: WorkspaceSettingsQuery) {
    this.initializationUrl =
      data.workspacePipedriveIntegration.initialization_url;
    this.billableOptions = [
      { value: "true", label: t`Billable` },
      { value: "false", label: t`Non-billable` },
    ];
    this.appStore.pipedriveStore.fetchOptions();
    this.form.init(
      data.workspacePipedriveIntegration.settings,
      this.appStore.workspaceStore.settings?.pipedriveEnabled,
    );
  }
}
