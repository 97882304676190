import { t } from "@lingui/macro";
import {
  Quote,
  QuoteEmailData,
  QuoteEmailDataDocument,
  QuoteEmailDataQuery,
  QuoteEmailDataQueryVariables,
  QuoteSendInput,
  SendQuoteDocument,
  SendQuoteMutation,
  SendQuoteMutationVariables,
} from "@src/__generated__/graphql";
import {
  EMPTY_EDITOR_LENGTH,
  TextEditorRef,
} from "@src/components/ui-kit/TextEditor/TextEditor";
import { SendQuoteModal } from "@src/components/widgets/Modals/ModalSendQuote/SendQuoteModal";
import { FetchHelper } from "@src/helpers/apollo/fetch";
import { MutationHelper } from "@src/helpers/apollo/mutation";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { formatMailContent } from "@src/utils/formatters";
import {
  email,
  minLength,
  multipleEmails,
  required,
} from "@src/utils/forms/validators";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { FieldState, FormState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import { createRef } from "react";

type TAdditionalData = {
  id: Quote["id"];
  onSubmit?: () => void;
};

export class SendQuoteModalStore implements BaseStore, ModalStore {
  appStore: AppStore;
  readonly modalId = "sendQuoteModal";

  contentEditorRef = createRef<TextEditorRef>();

  private fetcher = new FetchHelper<
    QuoteEmailDataQuery,
    QuoteEmailDataQueryVariables
  >(QuoteEmailDataDocument);
  private mutator = new MutationHelper<
    SendQuoteMutation,
    SendQuoteMutationVariables
  >(SendQuoteDocument);

  form = this.generateForm();
  @observable.ref attachments: NonNullable<QuoteEmailData["attachments"]> = [];

  @observable modalState = new DisclosureState<TAdditionalData>({
    onOpen: async () => {
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <SendQuoteModal />,
      });

      const quote = await this.fetchQuote();

      if (!quote) return;
      const content = formatMailContent(quote.content);

      this.form.$.recipients.onChange(quote.recipients?.join(", ") ?? "");
      this.form.$.subject.onChange(quote.subject);
      this.form.$.content.onChange(content);
      this.contentEditorRef.current?.setContent(content);
      this.attachments = quote.attachments ?? [];
    },
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
      this.modalState.additionalData = undefined;
      this.form = this.generateForm();
      this.form.reset();
      this.attachments = [];
    },
  });

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @computed get isLoading() {
    return this.fetcher.isLoading.value;
  }

  @computed get isSubmitting() {
    return this.mutator.isLoading.value;
  }

  @action.bound async serialize(): Promise<QuoteSendInput | undefined> {
    const { hasError } = await this.form.validate();
    if (hasError) return;
    if (!this.modalState.additionalData?.id) return;

    return {
      id: this.modalState.additionalData.id,
      content: this.form.$.content.$.replaceAll("<p></p>", "<br/>"),
      subject: this.form.$.subject.$ ?? "",
      recipients: [this.form.$.primary_recipient.$],
      other_recipients: this.form.$.recipients.$.replaceAll(" ", "").split(","),
    };
  }

  @action.bound async fetchQuote() {
    if (!this.modalState.additionalData?.id) return;
    const [data, error] = await this.fetcher.fetch({
      quoteId: this.modalState.additionalData.id,
    });

    if (error || !data.GetQuoteEmailData) return;

    return data?.GetQuoteEmailData;
  }

  @action.bound async sendEmail() {
    const formData = await this.serialize();
    if (!formData) return;

    const [data, error] = await this.mutator.mutate({
      input: formData,
    });

    if (error || !data.sendQuote) return;

    this.appStore.UIStore.toast({
      title: t`Quote was successfully sent`,
      status: "success",
    });

    this.modalState.additionalData?.onSubmit?.();
    this.modalState.onClose();
  }

  generateForm(init?: QuoteEmailData) {
    return new FormState({
      primary_recipient: new FieldState(
        init?.primary_recipient ?? "",
      ).validators(email, required),
      recipients: new FieldState(init?.recipients?.join(", ") ?? "").validators(
        multipleEmails,
        required,
      ),
      subject: new FieldState(init?.subject ?? "").validators(required),
      content: new FieldState(init?.content ?? "")
        .validators(minLength(EMPTY_EDITOR_LENGTH - 1), required)
        .enableAutoValidation(),
    });
  }
}
