import { FieldState, FormState } from "formstate";
import { makeObservable, observable } from "mobx";

export class LoginFormState {
  @observable state:
    | "loading"
    | "ready"
    | "submitting"
    | "error"
    | "session-error" = "loading";
  @observable _token = "";
  @observable redirect_to = "";
  readonly email = new FieldState<string>("");
  readonly password = new FieldState<string>("");
  readonly remember = new FieldState<boolean>(false);

  readonly form = new FormState({
    email: this.email,
    password: this.password,
    remember: this.remember,
  });

  constructor() {
    makeObservable(this);
  }
}
