import { t } from "@lingui/macro";
import {
  DeleteQuoteDocument,
  DeleteQuoteMutation,
  DeleteQuoteMutationVariables,
  GetBudgetQuotesQuery,
  ManualApproveQuoteDocument,
  ManualApproveQuoteMutation,
  ManualApproveQuoteMutationVariables,
  QuoteStatus,
} from "@src/__generated__/graphql";
import { BudgetModel } from "@src/components/modules/projects/budgets/models/budget";
import { MutationHelper } from "@src/helpers/apollo/mutation";
import { AppStore, appStore } from "@src/stores/AppStore";
import { action, computed, makeObservable, observable } from "mobx";

type Quote = NonNullable<GetBudgetQuotesQuery["getBudgetQuotes"]["data"]>[0];

export class BudgetQuoteModel {
  id: Quote["id"];
  number: Quote["number"];
  settings: Quote["settings"];
  budgets: BudgetModel[];
  @observable status: QuoteStatus;
  client_approval_date: Quote["client_approval_date"];
  sent_to_client_date: Quote["sent_to_client_date"];
  url: string;

  private deleteMutator = new MutationHelper<
    DeleteQuoteMutation,
    DeleteQuoteMutationVariables
  >(DeleteQuoteDocument);

  private manualApproveMutator = new MutationHelper<
    ManualApproveQuoteMutation,
    ManualApproveQuoteMutationVariables
  >(ManualApproveQuoteDocument);

  constructor(
    quote: Quote,
    private appStore: AppStore,
  ) {
    makeObservable(this);
    this.id = quote.id;
    this.number = quote.number;
    this.settings = quote.settings;
    this.status = quote.status;
    this.client_approval_date = quote.client_approval_date;
    this.sent_to_client_date = quote.sent_to_client_date;
    this.url = quote.url;
    this.budgets = quote.budgets.map((budget) => new BudgetModel(budget));
  }

  @computed get isManualApproving() {
    return this.manualApproveMutator.isLoading.value;
  }

  @computed get isDeleting() {
    return this.deleteMutator.isLoading.value;
  }

  @action.bound async delete() {
    await this.deleteMutator.mutate({ id: this.id });
    appStore.UIStore.toast({
      title: t`Quote deleted successfully`,
      status: "success",
    });
  }

  @action.bound async manualApprove() {
    const [data, error] = await this.manualApproveMutator.mutate({
      id: this.id,
    });

    if (error) {
      this.appStore.UIStore.toast({ status: "error" });
      return;
    }

    this.status = data.manualApproveQuote.status;
    this.client_approval_date = data.manualApproveQuote.client_approval_date;

    appStore.UIStore.toast({
      title: t`Quote approved successfully`,
      status: "success",
    });
  }

  @action.bound async download() {
    window.open(this.url, "_blank");
  }
}
