import { t } from "@lingui/macro";
import { captureEvent } from "@sentry/nextjs";
import {
  GlobalWorkspaceSettingsDocument,
  GlobalWorkspaceSettingsQuery,
  UpdateWorkspaceSettingsDocument,
  UpdateWorkspaceSettingsMutation,
  UpdateWorkspaceSettingsMutationVariables,
  WorkspaceSettingsDocument,
  WorkspaceSettingsQuery,
} from "@src/__generated__/urql-graphql";
import { ModalSettings } from "@src/components/widgets/Modals/ModalSettings";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { CompaniesSettingsStore } from "@src/stores/SettingsModalStore/CompaniesSettingsStore";
import { UserManagementSettingsStore } from "@src/stores/SettingsModalStore/UserManagementStore";
import { SettingsModalState } from "@src/stores/forms/SettingsModalState";
import { denominate } from "@src/utils/formatters";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { action, makeObservable, observable } from "mobx";
import { EXCHANGE_RATE_DENOMINATION } from "../forms/SettingsModalState/FinancialSettingsState";
import { FinancialSettingsStore } from "./FinancialSettingsStore";
import { NotificationSettingsStore } from "./NotificationSettingsStore";
import { PlanningUtilizationSettingsStore } from "./PlanningUtilizationSettingsStore";
import { ProjectsSettingsStore } from "./ProjectsSettingsStore";
import { TasksSettingsStore } from "./TasksSettingsStore";
import { BanksSettingsStore } from "./banks-settings-store";
import { PipedriveSettingsStore } from "./pipedrive-settings-store";

export type TCategory =
  | "notifications"
  | "tasks"
  | "companies"
  | "financial"
  | "banks"
  | "userManagement"
  | "planningUtilization"
  | "projects"
  | "pipedrive";

export class SettingsModalStore implements BaseStore, ModalStore {
  appStore: AppStore;
  notificationsStore: NotificationSettingsStore;
  tasksStore: TasksSettingsStore;
  companiesStore: CompaniesSettingsStore;
  financialStore: FinancialSettingsStore;
  banksStore: BanksSettingsStore;
  userManagementStore: UserManagementSettingsStore;
  planningUtilizationStore: PlanningUtilizationSettingsStore;
  projectsStore: ProjectsSettingsStore;
  pipedriveSettingsStore: PipedriveSettingsStore;

  readonly modalId = "settingsModal";

  @observable category: TCategory = "projects";
  @observable form: SettingsModalState;
  @observable isLoading = false;
  @observable isSubmitting = false;
  @observable modalState = new DisclosureState({
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
    },
    onOpen: () => {
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <ModalSettings />,
      });
      this.fetchSettings();
    },
  });

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
    this.form = new SettingsModalState(this);
    this.notificationsStore = new NotificationSettingsStore(
      appStore,
      this.form.notificationsState,
    );
    this.tasksStore = new TasksSettingsStore(appStore, this.form.tasksState);
    this.companiesStore = new CompaniesSettingsStore(
      appStore,
      this.form.companiesState,
    );
    this.financialStore = new FinancialSettingsStore(
      appStore,
      this.form.financialState,
    );
    this.banksStore = new BanksSettingsStore(appStore);
    this.userManagementStore = new UserManagementSettingsStore(
      appStore,
      this.form.userManagementState,
    );
    this.planningUtilizationStore = new PlanningUtilizationSettingsStore(
      appStore,
      this.form.planningUtilizationState,
    );
    this.projectsStore = new ProjectsSettingsStore(
      appStore,
      this.form.projectsState,
    );
    this.pipedriveSettingsStore = new PipedriveSettingsStore(
      appStore,
      this.form.pipedriveState,
    );
  }

  @action.bound
  setCategory(category: TCategory) {
    this.category = category;
  }

  @action.bound
  async onSubmit() {
    this.isSubmitting = true;

    const formResponse = await this.form.form.validate();
    if (formResponse.hasError) {
      console.error("Form has errors", this.form.form.error);
      this.isSubmitting = false;
      return;
    }

    try {
      const response = await client.mutation<
        UpdateWorkspaceSettingsMutation,
        UpdateWorkspaceSettingsMutationVariables
      >(UpdateWorkspaceSettingsDocument, {
        notifications: {
          notifications: this.notificationsStore.form.notifications.map(
            (notification) => ({
              id: notification.id,
              database_enabled: notification.$.database_enabled.$,
              mail_enabled: notification.$.mail_enabled.$,
              roles_to_notify: notification.$.roles_to_notify.$,
              threshold: notification.$.threshold.$
                ? parseInt(notification.$.threshold.$, 10)
                : null,
            }),
          ),
        },
        tasks: {
          show_done_tasks: this.tasksStore.form.form.$.show_done.$,
          task_priorities: this.tasksStore.form.form.$.priorities.$.map(
            (priority) => ({
              default: priority.$.default.$,
              id: priority.id,
              name: priority.$.name.$,
            }),
          ),
          task_statuses: this.tasksStore.form.form.$.statuses.$.map(
            (status) => ({
              background_color: status.$.background_color.$,
              default: status.$.default.$,
              done: status.$.done.$,
              foreground_color: status.$.foreground_color.$,
              id: status.id,
              name: status.$.name.$,
            }),
          ),
        },
        companies: {
          companies: this.companiesStore.form.companies.map((company) => ({
            bank_accounts: company.bank_accounts.$.bank_accounts.$.map(
              (bankAccount) => ({
                account_holder_name: bankAccount.$.account_holder_name?.$,
                bank_account: bankAccount.$.bank_account?.$,
                bank_code: bankAccount.$.bank_code?.$,
                bank_name: bankAccount.$.bank_name?.$,
                bic: bankAccount.$.bic.$,
                iban: bankAccount.$.iban.$,
                id: bankAccount.id,
              }),
            ),
            sequences: company.sequences.$.sequences.$.map((sequence) => ({
              format: sequence.$.format.$,
              id: sequence.id,
              next_number: Number(sequence.$.next_number.$),
              types: sequence.$.types.$,
            })),
            pdfSettings: {
              // footer: company.pdfSettings.$.footer.$,
              // text_on_invoice: company.pdfSettings.$.text_on_invoice.$,
              // show_contact_person: company.pdfSettings.$.show_contact_person.$,
              // show_project_codes: company.pdfSettings.$.show_project_codes.$,
              // show_full_bank_account_information:
              //   company.pdfSettings.$.show_full_bank_account_information.$,
              // show_amount_in_words:
              //   company.pdfSettings.$.show_amount_in_words.$,
              // show_variable_symbol:
              //   company.pdfSettings.$.show_variable_symbol.$,
              // show_tax_no: company.pdfSettings.$.show_tax_no.$,
              // show_payment_type: company.pdfSettings.$.show_payment_type.$,
              // show_date_of_supply: company.pdfSettings.$.show_date_of_supply.$,
              logo_assignable_file_id:
                company.pdfSettings.$.logo.$?.public_id ?? null,
              stamp_assignable_file_id:
                company.pdfSettings.$.stamp.$?.public_id ?? null,
            },
            city: company.city.$,
            country_id: company.country_id.$,
            deleted: company.deleted.$,
            id: company.id,
            internal_name: company.internal_name.$,
            is_default: company.is_default.$,
            name: company.name.$,
            registration_no: company.registration_no.$,
            street: company.street.$,
            tax_no: company.tax_no.$,
            vat_no: company.vat_no.$,
            zip: company.zip.$,
          })),
        },
        financial: {
          // TODO: Pridat field na show_full_bank_account_information
          // YES YES YES NO FORGETTI YES
          default_show_unit_price_on_invoices:
            this.financialStore.form.form.$.showUnitPrice.$,
          default_show_billing_accounting_on_invoices:
            this.financialStore.form.form.$.showBillingAccounting.$,
          currencies: this.financialStore.form.form.$.currencies.$.map(
            (currency) => ({
              code: currency.$.code.$,
              exchange_rate:
                currency.$.exchange_rate.$ === ""
                  ? null
                  : denominate(
                      Number(currency.$.exchange_rate.$),
                      EXCHANGE_RATE_DENOMINATION,
                    ),
            }),
          ),
          vat_rates: this.financialStore.form.form.$.vatRates.$.map(
            (vatRate) => ({
              id: vatRate.id,
              amount: Number(vatRate.$.amount.$) * 100,
              default: vatRate.$.default.$,
            }),
          ),
        },
        accountingAccounts: {
          accounting_accounts:
            this.financialStore.form.form.$.billingAccountingAccounts.$.map(
              ({ mutationInput }) => mutationInput,
            ).concat(
              this.financialStore.form.form.$.expensesAccountingAccounts.$.map(
                ({ mutationInput }) => mutationInput,
              ),
            ),
        },
        userManagement: {
          teams: this.userManagementStore.form.form.$.teams.$.map((team) => ({
            id: team.id,
            name: team.$.name.$,
            is_default: team.$.is_default.$,
            copy_users_from_team_id: team.$.copy_users_from_team_id?.$,
          })),
          require_two_factor_authentication:
            this.userManagementStore.form.form.$
              .require_two_factor_authentication.$,
        },
        planningUtilization: {
          default_user_plannable_capacity:
            Number(
              this.planningUtilizationStore.form.default_user_plannable_capacity
                .$,
            ) *
            60 *
            60,
          default_utilization_range_from:
            this.planningUtilizationStore.form.default_utilization_range_from.$,
          default_utilization_range_to:
            this.planningUtilizationStore.form.default_utilization_range_to.$,
          disable_home_office_in_planning:
            this.planningUtilizationStore.form.disable_home_office_in_planning
              .$,
          hide_planning_total_daily_capacity:
            this.planningUtilizationStore.form
              .hide_planning_total_daily_capacity.$,
          planning_alphabet_user_order:
            this.planningUtilizationStore.form.planning_alphabet_user_order.$,
          planning_sort_prioritize_time_specific_items:
            this.planningUtilizationStore.form
              .planning_sort_prioritize_time_specific_items.$,
          specific_time_in_planning_enabled:
            this.planningUtilizationStore.form.specific_time_in_planning_enabled
              .$,
          start_day_of_week:
            this.planningUtilizationStore.form.start_day_of_week.$,
          working_from: this.planningUtilizationStore.form.working_from.$,
          working_to: this.planningUtilizationStore.form.working_to.$,
        },
        planningViewSettings: {
          planning_view_settings: [
            {
              name: "allocation_item_style",
              value: this.planningUtilizationStore.form.allocation_item_style.$,
            },
            {
              name: "view_type",
              value: this.planningUtilizationStore.form.view_type.$,
            },
            {
              name: "adjust_allocation_height_by_duration",
              value:
                this.planningUtilizationStore.form.adjust_allocation_height_by_duration.$.toString(),
            },
            {
              name: "show_project",
              value:
                this.planningUtilizationStore.form.show_project.$.toString(),
            },
            {
              name: "show_task",
              value: this.planningUtilizationStore.form.show_task.$.toString(),
            },
            {
              name: "show_budget_item",
              value:
                this.planningUtilizationStore.form.show_budget_item.$.toString(),
            },
            {
              name: "show_note",
              value: this.planningUtilizationStore.form.show_note.$.toString(),
            },
            {
              name: "text_order",
              value: this.planningUtilizationStore.form.text_order.join(","),
            },
          ],
        },
        projects: {
          project_code_generator:
            this.projectsStore.form.project_code_generator.$,
          project_code_length: this.projectsStore.form.project_code_length.$,
          project_code_with_year:
            this.projectsStore.form.project_code_with_year.$,
          can_close_expense_budget_item_with_unapproved_expenses:
            this.projectsStore.form
              .can_close_expense_budget_item_with_unapproved_expenses.$,
          create_default_task_enabled:
            this.projectsStore.form.create_default_task_enabled.$,
          create_default_task_only_to_our_work_category:
            this.projectsStore.form
              .create_default_task_only_to_our_work_category.$,
          automate_invoicing_plan_approval:
            this.projectsStore.form.automate_invoicing_plan_approval.$,
        },
        pipedrive: this.appStore.workspaceStore.settings?.pipedriveEnabled
          ? await this.pipedriveSettingsStore.form.updateInput()
          : undefined,
      });

      if (!response.error) {
        this.appStore.UIStore.toast({
          title: t`Settings were updated successfully`,
          status: "success",
        });
        this.modalState.onClose();

        this.reloadGlobalWorkspaceSettings();
      }
    } catch {
      this.appStore.UIStore.toast({
        title: t`Unable to update settings. Please try again later.`,
        status: "error",
      });
    }

    this.isSubmitting = false;
  }

  private async fetchSettings() {
    this.isLoading = true;
    try {
      const { data } = await client.query<WorkspaceSettingsQuery>(
        WorkspaceSettingsDocument,
        {},
      );

      if (!data) {
        throw new Error("No data returned from the server");
      }
      if (!this.appStore.workspaceStore.settings) {
        throw new Error("No workspace settings found");
      }

      this.form.init(data, this.appStore.workspaceStore.settings);
      this.notificationsStore.init(data);
      this.companiesStore.init(data);
      this.projectsStore.init(data);
      this.banksStore.init(data);
      this.pipedriveSettingsStore.init(data);
    } catch (error) {
      this.modalState.onClose();
      this.appStore.UIStore.toast({
        title: t`Unable to load settings. Please try again later.`,
        status: "error",
      });
      captureEvent({
        message: "Unable to load settings",
        extra: { error },
      });
    }
    this.isLoading = false;
  }

  private async reloadGlobalWorkspaceSettings() {
    try {
      const { data } = await client.query<GlobalWorkspaceSettingsQuery>(
        GlobalWorkspaceSettingsDocument,
        {},
      );

      if (!data || !data.workspaceSettings) {
        throw new Error("No data returned from the server");
      }

      this.appStore.workspaceStore.from(data);
    } catch (error) {
      captureEvent({
        message: "Unable to reload global settings",
        extra: { error },
      });
    }
  }
}
