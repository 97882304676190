import { PipedriveStage } from "@src/__generated__/graphql";
import { IOption } from "@src/components/ui-kit";

export function pipedriveStages(
  stages?: PipedriveStage[] | undefined | null,
): IOption[] {
  if (!stages) return [];
  return stages.map((stage) => ({
    value: stage.pipedrive_stage_id,
    label: stage.name,
  }));
}
