import {
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { LoadingOverlay } from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import { TCategory } from "@src/stores/SettingsModalStore";
import { useStore } from "@src/utils/hooks";
import { compact } from "lodash";
import { observer } from "mobx-react-lite";
import { BanksTab } from "./banks";
import { Companies } from "./companies";
import { Financial } from "./financial";
import { Notifications } from "./notifications";
import { Pipedrive } from "./pipedrive";
import { PlanningUtilization } from "./planning-utilization";
import { Projects } from "./projects";
import { Tasks } from "./tasks";
import { UserManagement } from "./user-management";

export const ModalSettings = observer(function ModalSettings() {
  const { settingsModalStore, workspaceStore } = useStore();
  const menu: { key: TCategory; label: string; formHasError: boolean }[] =
    compact([
      {
        key: "notifications",
        label: t`Notifications`,
        formHasError: settingsModalStore.notificationsStore.form.form.hasError,
      },
      {
        key: "tasks",
        label: t`Tasks`,
        formHasError: settingsModalStore.tasksStore.form.form.hasError,
      },
      {
        key: "companies",
        label: t`Companies`,
        formHasError: settingsModalStore.companiesStore.form.form.hasError,
      },
      {
        key: "financial",
        label: t`Financial`,
        formHasError: settingsModalStore.financialStore.form.form.hasError,
      },
      workspaceStore.settings?.enableBank && {
        key: "banks",
        label: t`Banks`,
        formHasError: false,
      },
      {
        key: "userManagement",
        label: t`User Management`,
        formHasError: settingsModalStore.userManagementStore.form.form.hasError,
      },
      {
        key: "planningUtilization",
        label: t`Planning & Utilization`,
        formHasError:
          settingsModalStore.planningUtilizationStore.form.form.hasError,
      },
      {
        key: "projects",
        label: t`Projects`,
        formHasError: settingsModalStore.projectsStore.form.form.hasError,
      },
      {
        key: "pipedrive",
        label: t`Pipedrive`,
        formHasError: settingsModalStore.pipedriveSettingsStore.form.hasError,
      },
    ]);

  return (
    <Modal
      isCentered
      isOpen
      onClose={settingsModalStore.modalState.onClose}
      size={{
        base: "full",
        md: "6xl",
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton zIndex="2" bg="white" />
        <LoadingOverlay
          isLoading={settingsModalStore.isLoading}
          position="absolute"
        >
          <Grid templateRows="60vh auto" templateColumns="25% 1fr">
            <GridItem
              borderInlineEndColor="grey.200"
              borderInlineEndStyle="solid"
              borderInlineEndWidth="1px"
              rowSpan={2}
            >
              <ModalHeader as="h2">
                <Trans>Settings</Trans>
              </ModalHeader>
              <ButtonGroup
                flexDir="column"
                w="full"
                mt="1"
                orientation="vertical"
                paddingBlock="1"
                paddingInline="3"
                variant="ghost"
              >
                {menu.map((item) => (
                  <Button
                    key={item.key}
                    justifyContent="start"
                    isActive={settingsModalStore.category === item.key}
                    onClick={() => settingsModalStore.setCategory(item.key)}
                    rightIcon={
                      item.formHasError ? (
                        <Tooltip
                          hasArrow
                          label={t`This section contains errors that need to be fixed in order to update the settings.`}
                        >
                          <span tabIndex={0}>
                            <Icon name="alert-triangle" color="red.500" />
                          </span>
                        </Tooltip>
                      ) : undefined
                    }
                  >
                    {item.label}
                  </Button>
                ))}
              </ButtonGroup>
            </GridItem>
            <GridItem pos="relative" overflowY="auto" paddingInline="6">
              {
                {
                  notifications: <Notifications />,
                  tasks: <Tasks />,
                  companies: <Companies />,
                  financial: <Financial />,
                  banks: <BanksTab />,
                  userManagement: <UserManagement />,
                  planningUtilization: <PlanningUtilization />,
                  projects: <Projects />,
                  pipedrive: <Pipedrive />,
                }[settingsModalStore.category]
              }
            </GridItem>
            <GridItem
              textAlign="end"
              borderBlockStartColor="grey.200"
              borderBlockStartStyle="solid"
              borderBlockStartWidth="1px"
              paddingBlock="2"
              paddingInline="6"
            >
              <Button
                colorScheme="violet"
                isLoading={settingsModalStore.isSubmitting}
                onClick={() => settingsModalStore.onSubmit()}
              >
                <Trans>Update</Trans>
              </Button>
            </GridItem>
          </Grid>
        </LoadingOverlay>
      </ModalContent>
    </Modal>
  );
});
