import {
  PlanningDailyViewPeopleDocument,
  PlanningDailyViewPeopleQuery,
  PlanningDailyViewPeopleQueryVariables,
} from "@src/__generated__/graphql";
import { PlanningDailyViewUserByDayModel } from "@src/components/modules/resource-planning/timeline/models";
import { FetchHelper } from "@src/helpers/apollo/fetch";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { toApiDate } from "@src/utils/dates";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { action, computed, makeObservable } from "mobx";
import { ResourcePlanningViewStore } from "./extendables/ResourcePlanningViewStore";

export class ResourcePlanningDayStore
  extends ResourcePlanningViewStore
  implements BaseStore
{
  private static perPage = 10;
  appStore: AppStore;
  pagination = new PaginationState("planning-day-pagination", {
    page: 0,
    perPage: ResourcePlanningDayStore.perPage,
  });

  fetcher = new FetchHelper<
    PlanningDailyViewPeopleQuery,
    PlanningDailyViewPeopleQueryVariables
  >(PlanningDailyViewPeopleDocument);

  constructor(appStore: AppStore) {
    super(
      appStore,
      (loadForAllUsers, silent) => this.fetchData(loadForAllUsers, silent),
      () => this.reset(),
    );
    makeObservable(this);
    this.appStore = appStore;
  }

  @computed get canLoadMore(): boolean {
    return (
      (this.pagination.total === undefined &&
        this.pagination.currentPage === 0) ||
      this.pagination.hasNextPage ||
      (this.pagination.lastItem ?? 0) < (this.pagination.total ?? 0)
    );
  }

  @computed get loadedUserIds(): string[] {
    return this.data.map(({ user }) => user.id);
  }

  @action.bound reset() {
    this.pagination.resetPage();
    this.pagination.setPerPage(ResourcePlanningDayStore.perPage);
    this.data = [];
  }

  @action.bound private fetchData = async (
    loadForAllUsers?: boolean,
    silent?: boolean,
  ) => {
    const [from, to] = this.selectedDateRange;

    const [data, error] = await this.fetcher.fetch(
      {
        dailyViewFilters: {
          from: toApiDate(from),
          to: toApiDate(to),
          search: this.queryParams.filters.search,
          first: loadForAllUsers
            ? this.pagination.asParams.page * this.pagination.asParams.first
            : this.pagination.asParams.first,
          page: loadForAllUsers ? 1 : this.pagination.asParams.page + 1,
          where: this.queryParams.filters.where!,
        },
      },
      undefined,
      undefined,
      silent,
      "network-only",
    );

    if (!data || error) {
      this.data = [];
      return;
    }

    const { planningDailyViewPeople } = data;

    this.lastSyncedAt = data.planningDailyViewPeople.last_updated_at;

    if (loadForAllUsers) {
      this.data = planningDailyViewPeople.data.map(
        (item) => new PlanningDailyViewUserByDayModel(item),
      );
    } else {
      planningDailyViewPeople.data
        .filter((item) => !this.loadedUserIds.includes(item.user.id))
        .forEach((item) => {
          this.data.push(new PlanningDailyViewUserByDayModel(item));
        });
      this.pagination.setFromPaginatorInfo(
        data.planningDailyViewPeople.paginatorInfo,
      );
    }
  };
}
